import React, { useEffect } from "react";
import ModalTemplate from "../components/ModalTemplate";

export const Dashboard = (props) => {
    useEffect(() => {
        document.title = "Ezymem | Dashboard";
        sessionStorage.setItem('activeItem', 'home');
    }, []);
    if (props.jsonData.length === 0)
        return (<h1>Loading...</h1>)

    const countByCategory = props.jsonData[1].data.reduce((count, obj) => {
        const category = obj['Categorie Obiect'];
        count[category] = (count[category] || 0) + 1;
        return count;
    }, {});

    const countByFunctional = props.jsonData[0].data.reduce((count, obj) => {
        const category = obj['Stare'];
        count[category] = (count[category] || 0) + 1;
        return count;
    }, {});

    const percentages = [
        (countByFunctional["Functional"]+countByFunctional["Inlocuit"])/props.jsonData[0].data.length *100,
        (countByFunctional["In reparatie"]+countByFunctional["Partial Functional"])/props.jsonData[0].data.length *100,
        (countByFunctional["Nefunctional"])/props.jsonData[0].data.length *100
    ]

    return (
        <div className="dashboard">
            <div className="modals small inline">
                <ModalTemplate type="small" name='heartbeat' number={props.jsonData[0].data.length} color={'aliceblue'} description={'Dispozitive Medicale'} />
                <ModalTemplate type="small" name='settings' number={countByCategory['Piesa de Schimb']} color={'cornsilk'} description={'Piese de Schimb'} />
                <ModalTemplate type="small" name='box' number={countByCategory['Consumabil']} color={'bisque'} description={'Consumabile'} />
                <ModalTemplate type="small" name='plus' number={countByCategory['Accesoriu']} color={'mistyrose'} description={'Accesorii'} />
            </div>
            <div className="modals big inline">
                <ModalTemplate type="today-calendar" description={'dispozitive medicale'} />
                <ModalTemplate type="func-chart" percentages={percentages} description={'dispozitive medicale'} />
            </div>
        </div>
    );
};
