import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Checkbox, Icon, Search, Pagination, Dropdown } from 'semantic-ui-react';
import { AddEntryModal } from '../components/ModalAdd';
import ViewTargetElement from '../components/ViewTargetElement';

export const Inventory = (props) => {
  /* Modal Variables */
  const [jsonData, setJsonData] = useState([]);
  const [keys_data, setKeysData] = useState({});
  const [pendingChanges, setPendingChanges] = useState({});
  const [open, setOpen] = useState(false);
  const [displayedData, setDisplayedData] = useState([]);

  /* Add Modal Data */
  const [jsonDataCategories, setJsonDataCategories] = useState([]);

  useEffect(() => {
    document.title = `Ezymem | ${props.title}`;
    sessionStorage.setItem('activeItem', document.location.pathname === '/inventory' ? 'inventoryD' : 'inventoryO');
    setKeysData({});

    const fetchData = async () => {
      try {
        const [response1, response2] = await Promise.all([
          fetch(props.filePath),
          fetch(props.catPath)
        ]);
        if (!response1.ok || !response2.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response1.json();
        const catData = await response2.json();

        Object.keys(data).includes("default") && setKeysData(data.default);

        setJsonData(data.data);
        setJsonDataCategories(catData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    setShowDetails(false);
    // setModifyDetails(false);
    fetchData();
  }, [props]);

  useEffect(() => {
    setPendingChanges(keys_data);
  }, [keys_data]);

  /*Sorting*/
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  /*Pagination*/
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const totalItems = jsonData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleItemsPerPageChange = (e, { value }) => {
    setItemsPerPage(value);
    setActivePage(1);
  };

  const itemsPerPageOptions = [5, 10, 20, 50];

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  /* View Target Details */
  const [showDetails, setShowDetails] = useState(false);
  const [modifyDetails, setModifyDetails] = useState(false);
  const [targetData, setTargetData] = useState(undefined);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (jsonData.length > 0) {
      if (!Object.entries(keys_data).length) {
        const initialKeysData = Object.keys(jsonData[0]).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});
        setKeysData(initialKeysData);
      }

      const data = jsonData.slice(
        (activePage - 1) * itemsPerPage,
        activePage * itemsPerPage
      );
      setDisplayedData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonData, activePage, itemsPerPage, modifyDetails]);

  if (!displayedData || displayedData.length === 0) {
    return <div>No data available</div>;
  }

  if (!jsonDataCategories || jsonDataCategories === undefined) {
    return <div>...Loading!</div>
  }

  const handleCheckboxChange = (key) => {
    setPendingChanges((prevChanges) => ({
      ...prevChanges,
      [key]: !prevChanges[key],
    }));
  };

  const handleCheckboxChangeAll = () => {
    const isAllSelected = Object.values(pendingChanges).every((isChecked) => isChecked);

    setPendingChanges((prevChanges) => {
      const updatedChanges = {};

      for (const key in prevChanges) {
        updatedChanges[key] = !isAllSelected;
      }

      return updatedChanges;
    });
  };

  const handleSave = () => {
    const updatedPendingChanges = {};
    for (const key in pendingChanges) {
      updatedPendingChanges[key] = pendingChanges[key];
    }
    setKeysData((prevKeysData) => ({
      ...prevKeysData,
      ...updatedPendingChanges,
    }));
    setPendingChanges({});
    setOpen(false);
  };

  const handleSort = (clickedColumn) => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setDirection('ascending');
      setJsonData([...jsonData].sort((a, b) => (a[clickedColumn] > b[clickedColumn]) ? 1 : -1));
    } else {
      setDirection(direction === 'ascending' ? 'descending' : 'ascending');
      setJsonData([...jsonData].reverse());
    }
  };

  const handleViewClick = (isVisibile, targetData = undefined) => {
    setTargetData(targetData);
    setShowDetails(isVisibile);
  };

  const handleModifyClick = () => {
    setModifyDetails(!modifyDetails);
  }



  const handleSubmit = () => {
      console.log("Changes Form Data: ", formData);
      setFormData({});
      handleModifyClick();
      handleViewClick(false);
    };
  
    const updateFormData = (updatedData) => {
      setFormData({
        ...formData,
        ...updatedData,
      });
    };

  if (showDetails === true) {
    return (
      <div className='modal'>
        <div className="button-container">
          <div className="button-row">
            <Button color="gray" onClick={() => handleViewClick(false)}><Icon name="arrow left" />Inapoi la Inventar</Button>
            <Button color="gray" disabled ><Icon name="external" />Genereaza Document</Button>
            <Button color="blue" onClick={() =>!modifyDetails ? handleModifyClick() : handleSubmit()}>
              <Icon name={modifyDetails ? "check" : "plus" } /> {modifyDetails ? 'Salveaza' : 'Modifica'}
            </Button>
          </div>
        </div>
        <ViewTargetElement
          title={props.title}
          data={targetData}
          catData={jsonDataCategories}
          modifyDetails={modifyDetails}
          updateFormData={updateFormData}
        />
      </div>
    );
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{ height: 'auto', width: '70%' }}
      >
        <Modal.Header>Filtrare Avansata</Modal.Header>
        <Modal.Content>
          <div className="flex-container">
            {Object.entries(keys_data).map(([key, isChecked], index) => (
              <div className="flex-item" key={index}>
                <Checkbox
                  label={key}
                  checked={pendingChanges[key] ?? isChecked}
                  onChange={() => handleCheckboxChange(key)}
                />
              </div>
            ))}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleCheckboxChangeAll} icon color='grey' style={{ float: 'left' }}>
            {Object.values(pendingChanges).every((isChecked) => isChecked) ? 'Deselecteaza Tot' : 'Selecteaza Tot'}
          </Button>
          <Button onClick={() => setOpen(false)} icon color='grey'>
            <Icon name="arrow left" />
            Inapoi
          </Button>
          <Button onClick={() => handleSave()} icon color='blue'>
            <Icon name="checkmark" />
            Salvare
          </Button>
        </Modal.Actions>
      </Modal>
      <div className='modal' style={{ padding: '15px', overflow: 'auto' }}>
        <h1>{props.title}</h1>
        <div>
          <div style={{ margin: ' 0 0 20px 0' }}>
            <Button disabled icon><Icon name='external alternate' /><span style={{ marginLeft: '5px' }}>Exporta</span></Button>
            <span style={{ margin: '0 10px' }} />
            <AddEntryModal title={props.title} catData={jsonDataCategories} />
          </div>
          <span style={{ display: 'block', margin: '5px 0' }} />
          <div style={{ margin: ' 0 0 20px 0' }}>
            <Search placeholder="Cauta" style={{ display: 'inline-block' }} />
            <span style={{ margin: '0 10px' }} />
            <Button content="Filtrare Avansata" disabled />
            <span style={{ margin: '0 10px' }} />
            <Button content="Afisare Coloane" onClick={() => setOpen(true)} />
            <span style={{ margin: '0 10px' }} />
            <Dropdown
              selection
              options={itemsPerPageOptions.map((option) => ({
                key: option,
                text: `${option} items per page`,
                value: option,
              }))}
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            />
          </div>
        </div>
        <Table celled compact size='small'>
          <Table.Header>
            <Table.HeaderCell />
            {Object.keys(displayedData[0])
              .filter((key) => keys_data[key] !== false)
              .map((key, index) => (
                <Table.HeaderCell
                  key={index}
                  onClick={() => handleSort(key)}
                  className={column === key ? `sorted ${direction}` : ''}
                >
                  {key}
                </Table.HeaderCell>
              ))}
            <Table.HeaderCell />
          </Table.Header>
          {displayedData.map((row, index) => (
            <Table.Body key={index}>
              <Table.Row key={index}>
                {Object.keys(row).map((key, subIndex) => {
                  if (subIndex === 0) {
                    return <Table.Cell key={subIndex}><Checkbox /></Table.Cell>;
                  }
                  return null;
                })}
                {Object.keys(row).map((key, subIndex) => {
                  if (keys_data[key] === true) {
                    return <Table.Cell key={subIndex}>{row[key]}</Table.Cell>;
                  }
                  return null;
                })}
                <Table.Cell key={index}>
                  <Icon className='shadyButton' name='eye' color='blue' onClick={() => handleViewClick(true, row)} />
                  <Icon className='shadyButton' name='trash' color='red' onClick={() => {
                    console.log({'change': `delete entry ${Object.values(displayedData[index])[0]}`});
                  }} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ))}
        </Table>
        <Pagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          ellipsisItem={'...'}
          boundaryRange={1}
        />
      </div >
    </>
  );
}
