import React from "react";
import { Icon, Table } from "semantic-ui-react";

const TodayCalendar = () => {
    return (
        <div className={'todays-calendar modal w70'} style={{
            background: 'rgba(110,110,110,0.4)',
            backdropFilter: 'blur(25px)'
        }}>
            <span style={{
                width: '100%',
                textAlign: 'center',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%) rotate(45deg)',
                margin: 0,
                color: 'white',
                WebkitTextStroke: '2px black',
                fontSize: 42
            }}>WORK IN PROGRESS!</span>
            <h3 style={{ margin: '5% 0 5% 5%' }}><Icon name="calendar" /> Activitatile de astazi</h3>
            <div className="calendar">
                <Table basic='very' collapsing style={{ width: '90%', margin: '0 auto' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Numar Inventar</Table.HeaderCell>
                            <Table.HeaderCell>Denumire Generica</Table.HeaderCell>
                            <Table.HeaderCell>Denumire Comerciala</Table.HeaderCell>
                            <Table.HeaderCell>Tip Activitate</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>876364</Table.Cell>
                            <Table.Cell>Monitor Pacient</Table.Cell>
                            <Table.Cell>SC4567</Table.Cell>
                            <Table.Cell>Verificare Periodica ANMDMR</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>876368</Table.Cell>
                            <Table.Cell>Aparat Anestezie</Table.Cell>
                            <Table.Cell>Comen AX</Table.Cell>
                            <Table.Cell>Verificare Metrologica</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>876412</Table.Cell>
                            <Table.Cell>Ventilator Mecanic</Table.Cell>
                            <Table.Cell>Carescape R860</Table.Cell>
                            <Table.Cell>Intretinere si Verificare</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}

export default TodayCalendar;