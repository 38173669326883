import React, { useEffect } from "react";
import CalendarView from "../components/CalendarView";
import { Segment } from "semantic-ui-react";

export const Calendar = (props) => {
    useEffect(() => {
        document.title = "Ezymem | Calendar";
        sessionStorage.setItem('activeItem', 'agenda');
    }, []);
    return(
        <div className="agenda">
            <Segment className="stuff" style={{ margin: 0 }}>
                <Segment attached><h1>Header1</h1></Segment>
                <Segment attached>
                    <div>
                        <h2>header2</h2>
                    </div>
                    <div>
                        <h3>header3</h3>
                    </div>
                    <div>
                        <h4>header4</h4>
                    </div>
                </Segment>
            </Segment>
            <div className="calendar-wrapper">
                <CalendarView />
            </div>
        </div>
    )
}