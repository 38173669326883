import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal, Button, Icon, Menu, Segment, Grid, Input, Dropdown } from 'semantic-ui-react';

export const ModalTemplatePage = (props) => {
    const [entries, setEntries] = useState(props.entries ?? {});
    
    const disabled = props.disabled ?? false;
    
    const inputDictionary = props.inputs;
    const active = props.active;
    // const type = props.type;
    

    if (!props.inputs || props.inputs === undefined) {
        return <div>Loading...</div>;
    }

    let dictionary = inputDictionary[active];

    const handleInputChange = (e, {type, name, value}) => {
        setEntries({
            ...entries,
            [name]: type === 'file' ? e.target.files[0] : value,
        });
        props.updateFormData({[name]: type === 'file' ? e.target.files[0] : value});
    };

    return (
        <Grid columns={3} stackable>
            {Object.keys(dictionary).map((key, index) => (
                <Grid.Column key={index}>
                    <div>
                        <span>{key}{dictionary[key].required ? <span style={{ color: 'red' }}>*</span> : <></>}</span><br />
                        {dictionary[key].options ?
                            <Input disabled={disabled} fluid
                                required={dictionary[key].required}
                                style={{ width: '100%' }}
                            >
                                <Dropdown disabled={disabled} style={{ width: '100%' }}
                                    placeholder='Selecteaza...'
                                    selection
                                    name={key}
                                    value={entries[key] ?? ''}
                                    onChange={handleInputChange}
                                    options={[
                                        { key: 'default', text: entries[key], value: entries[key] }, //#TODO remove this in production!!
                                        ...dictionary[key].options.map((name, index) => ({
                                            key: `option${index}`,
                                            text: name,
                                            value: name
                                        }))
                                    ]}
                                />
                            </Input>
                            : <Input disabled={disabled} fluid
                                type={dictionary[key].pdf ? 'file' : dictionary[key].date ? 'date' : 'text'}
                                icon={dictionary[key].pdf ? 'paperclip' : dictionary[key].date ? 'calendar alternate outline' : ''}
                                iconPosition='left'
                                required={dictionary[key].required}
                                name={key}
                                value={!dictionary[key].pdf ? (entries[key] ?? '') : undefined}
                                style={{ width: '100%' }}
                                onChange={handleInputChange}
                            />}
                    </div>
                </Grid.Column>
            ))}
        </Grid>
    );
};

export const AddEntryModal = (props) => {
    const jsonData = props.catData;
    
    const [modalOpen, setModalOpen] = useState(false);
    const [activePanel, setActivePanel] = useState(Object.keys(jsonData)[0]);

    useEffect(() => {
        if (Object.keys(jsonData)[0] !== activePanel) setActivePanel(Object.keys(jsonData)[0]);
    // eslint-disable-next-line
    }, [modalOpen])

    const [formData, setFormData] = useState({});

    const handleItemClick = (e, { name }) => setActivePanel(name);

    const handleSubmit = () => {
        console.log("Form Data: ", formData);
        setFormData({});
        setModalOpen(false);
    };

    const updateFormData = (updatedData) => {
        setFormData({
            ...formData,
            ...updatedData,
        });
    };

    return (
        <>
            <Button icon color='blue' onClick={() => setModalOpen(true)}><Icon name='plus' /><span style={{ marginLeft: '5px' }}>Adauga</span></Button>

            <Modal open={modalOpen} closeOnDimmerClick={false}
                style={{ width: '75%', height: '75%' }} onClose={() => setModalOpen(false)}>
                <Modal.Header style={{ borderBottom: '0' }}>
                    <h2>{props.title} - Adaugare</h2>
                    <sub>Campurile marcate cu "<span style={{ color: 'red' }}>*</span>" sunt obligatorii</sub>
                    <Menu pointing secondary borderless>
                        {Object.keys(jsonData).map((key, index) => {
                            return (
                                <Menu.Item
                                    key={index}
                                    index={index}
                                    name={key}
                                    active={activePanel === key}
                                    onClick={handleItemClick}
                                />
                            );
                        })}
                    </Menu>
                </Modal.Header>
                <Modal.Content style={{ height: '75%', overflowY: 'auto' }}>
                    <Segment basic>
                        <ModalTemplatePage
                            inputs={jsonData}
                            active={activePanel}
                            type='3Columns'
                            updateFormData={updateFormData}
                        />
                    </Segment>
                </Modal.Content>
                <Modal.Actions style={{ position: 'sticky', bottom: '0' }}>
                    <Button onClick={() => setModalOpen(false)}>
                        <Icon name='arrow left' />Inapoi la Inventar
                    </Button>
                    <Button color='blue' onClick={handleSubmit}>
                        Adauga
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
