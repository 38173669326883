import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Header, Message, Form, Segment } from "semantic-ui-react";

import EZYMEM from "../svg/EZYMEM";

export const Login = () => {
    const [name,setName] = useState(undefined);
    const [password,setPassword] = useState(undefined);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleLogin = () => {
        const getCookie = () => {
            const cookies = document.cookie.split(';');
            for(const cookie of cookies){
                const [cookieName, cookieValue] = cookie.split('=');
                sessionStorage.setItem(cookieName, cookieValue);
            }
        }
        setIsLoading(true);
        setTimeout(() => {
            sessionStorage.setItem('activeItem', 'home');

            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 1);

            // Format the expiration date for the cookie string
            const expires = `expires=${expirationDate.toUTCString()}`;

            document.cookie = `username=${name}; ${expires}; path=/`

            getCookie();

            navigate('/dashboard');
            setIsLoading(false);
        }, 3000);

    }

    useEffect(() => {
        document.title = "Ezymem | Login";
    }, []);

    const loginPageStyle = {
        margin: '0',
        padding: '0',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(to top, #ffffff, #6495ed)'
    };

    return (
        <Container style={loginPageStyle}>
            <Grid
                textAlign="center"
                verticalAlign="middle"
                style={{ 
                    height: "100vh",
                    background: 'rgba(255,255,255,0.1)',
                    backdropFilter: 'blur(10px) saturate(1.3)',
                    padding: '15px'
                }}
            >
                <Grid.Column style={{ maxWidth: 450 }}>
                    <EZYMEM style={{width: '100%', marginBotton: '20px'}} />
                    <Header as="h2" color="teal" textAlign="center">
                        Log-in to your account
                    </Header>
                    <Form size="large">
                        <Segment stacked>
                            <Form.Input
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder="Nume Utilizator"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Parola"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <Form.Button color="teal" fluid size="large" loading={isLoading} onClick={handleLogin}>
                                Login
                            </Form.Button>
                        </Segment>
                    </Form>
                    <Message>
                        New to us? <a href="/dummy">Sign Up</a>
                    </Message>
                </Grid.Column>
            </Grid>
        </Container>
    )
}
