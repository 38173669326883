import React from "react";
import { Icon } from "semantic-ui-react";

import TodayCalendar from "./TodayCalendar";
import Charts from "./Charts";

function ModalTemplate(props) {
    const iconStyle = {
      backgroundColor: props.color ? props.color : 'transparent',
      borderRadius: '25%',
      position: 'absolute',
      width: '100px', height: '100px',
      padding: '10px',
    };

    const iconImageSytle = {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }

    const numberStyle = {
      fontSize: '26px',
      marginLeft: '125px',
      position: 'absolute',
    };

    const descriptionStyle = {
      fontSize: '18px',
      position: 'relative',
      margin: '50px 0 0 125px',
    };

    if(props.type === 'small')
      return (
        <div className={props.type + ' modal'} style={{position: 'relative'}}>
          <div style={{
            margin: '0',
            padding: '10px',
            position: 'absolute',
            top: '45%',
            transform: 'translateY(-50%)'
            }}>
            <div className="icon" style={iconStyle}><Icon name={props.name} size="huge" style={iconImageSytle}/></div>
            <div className="number" style={numberStyle}><p>{props.number}</p></div>
            <div className="description" style={descriptionStyle}><p>{props.description}</p></div>
          </div>
        </div>
      );
    else if(props.type === 'today-calendar')
      return (<TodayCalendar />);
    else if(props.type === 'func-chart')
        return (<Charts percentages={props.percentages} />);
  }
  
  export default ModalTemplate;
  