import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Menu, Sidebar, Image, /*Search*/ Icon, Dropdown, Button } from "semantic-ui-react";

import EZYMEM from "../svg/EZYMEM";
import EZYMEMSmall from "../svg/EZYMEMSmall";

import navbarTitles from "./constants/NavbarConstants.json";

const ToolbarComponent = () => {
  const [activeItem, setActiveItem] = useState(sessionStorage.getItem("activeItem") || null);
  const [isExtended, setIsExtended] = useState(false);
  const [isBurgerMenuVisible, setIsBurgerMenuVisible] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const username = sessionStorage.getItem("username");
  const [isLogin, setIsLogin] = useState(!!username);

  useEffect(() => {
    setActiveItem(sessionStorage.getItem("activeItem"));
  }, [])
  
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
      if (window.innerWidth > 768) {
        setIsBurgerMenuVisible(false); // Close burger menu on resize for larger screens
      }
    };
    
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleExtension = (val) => { setIsExtended(val); };

  const navigate = useNavigate();
  
  const navigator = (path, itemName) => {
    navigate(path);
    // sessionStorage.setItem("activeItem", itemName);
    setActiveItem(itemName);
    setIsBurgerMenuVisible(false);
  };
  
  const rightIconsStyle = {
    margin: '0 10px',
  };
  
  const NavbarLogin = () => {
    return(
      <Dropdown
              trigger={
                <span>
                  <Image avatar src='https://react.semantic-ui.com/images/avatar/large/ade.jpg' /> {username}
                </span>
              }
              pointing='top left'
              icon={null}
              style={rightIconsStyle}
        >
          <Dropdown.Menu>
            <Dropdown.Item disabled key='user' text='Account' icon='user' />
            <Dropdown.Item disabled key= 'settings' text= 'Settings' icon= 'settings' />
            <Dropdown.Item key= 'sign-out' text= 'Sign Out' icon= 'sign out'
              onClick={() => {
                localStorage.setItem('username', undefined);
                document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setIsLogin(false);
                navigator('/login', 'login');
              }} />
          </Dropdown.Menu>
        </Dropdown>
    );
  };

  return (
    <>
      <Menu
        fixed="top"
        style={{ height: '70px', zIndex: '2' }}
      >
        <Menu.Item active={activeItem === 'home'} as='a'
          className="logo-icon"
          onClick={() => isDesktop?navigator('/dashboard', 'home'):setIsBurgerMenuVisible(!isBurgerMenuVisible)}
          style={{ maxHeight: '70px', maxWidth: isDesktop ? '250px' : '70px', padding: isDesktop ? '20px' : '10px', overflow: 'hidden', background: 'white' }}
        >
          {isDesktop ? (<EZYMEM />) : (<EZYMEMSmall />)}
        </Menu.Item>
        {/* <Menu.Item style={{ margin: '0 auto' }}>
          <Search placeholder="Cautare..." icon="search" />
        </Menu.Item> */}
        <Menu.Item position="right">
          <Icon disabled name="chat" style={rightIconsStyle} />
          <Icon disabled name="bell" style={rightIconsStyle} />
          {
          isLogin ? <NavbarLogin/>
              : 
              <Button 
                animated='vertical'
                circular
                basic
                style={{ boxShadow: 'none' }}
                onClick={() => navigator('/login', 'login')}>
                <Button.Content visible>
                  <Image avatar src='https://www.pngarts.com/files/10/Default-Profile-Picture-Transparent-Image.png'/>
                </Button.Content>
                <Button.Content hidden>Account</Button.Content>
              </Button>
          }
        </Menu.Item>
      </Menu>
      {isDesktop ? (
        <Sidebar
          as={Menu}
          onMouseEnter={() => handleExtension(true)}
          onMouseLeave={() => handleExtension(false)}
          className="left-sidebar"
          icon={!isExtended}
          vertical visible={true}
          style={{ width: isExtended ? '250px' : '80px', transition: 'width 0.5s', zIndex: '1' }}
        >
          <Menu.Item style={{ height: '70px' }} />
          {navbarTitles.map((element, index) => {
            return(
              <Menu.Item 
                key={index} index={index}
                disabled={element.disabled} 
                active={activeItem === element.activeItem}
                as='a' style={{ height: '50px' }}
                onClick={() => {
                  localStorage.setItem('activeItem', element.activeItem);
                  navigator(element.link, element.activeItem);
                }}>
                  <p><Icon name={element.iconName} />{isExtended ? ` ${element.extendedText}` : ''}</p>
                </Menu.Item>
            );
          })}
        </Sidebar>
      ) : (
        <></>
      )}
      {isBurgerMenuVisible && (
        <Sidebar
          as={Menu}
          fixed='top'
          vertical
          icon='labeled'
          visible={isBurgerMenuVisible}
          style = {{ zIndex: '1', transition: 'display 0.5' }}
          onClick = {() => setIsBurgerMenuVisible(false)}
        >
          <div className="burger-menu" style={{ marginTop: '70px' }}>
          {navbarTitles.map((element, index) => {
            return(
              <Menu.Item 
                key={index}
                index={index}
                disabled={element.disabled}
                active={activeItem === element.activeItem}
                as='a'
                style={{ height: '50px' }}
                onClick={() => navigator(element.link, element.activeItem)}
              >
                <p><Icon name={element.iconName} />{element.extendedText}</p>
              </Menu.Item>
            );
          })}
          </div>
        </Sidebar>
      )}
      <div id="article">
        <Outlet />
      </div>
      <footer>
        <span className="left">EZYMEM Corporation</span>
        <span className="right">ver. pre-alpha1</span>
      </footer>
    </>
  );
}

export const Navbar = () => {
  return (
    <ToolbarComponent />
  );
}
