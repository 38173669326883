export const months = [
    'Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie',
    'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'
  ];
  
  export const days = [
    'Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'
  ];

export function getDaysInMonth(year, month) {
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    return lastDayOfMonth;
  }

export function getCurrentDate() {
    var currentDate = new Date();
  
    var year = currentDate.getFullYear();
    var monthName = months[currentDate.getMonth()];
    var month = currentDate.getMonth() +1;
    var dayName = days[currentDate.getDay()];
    var day = currentDate.getDate();

    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();
    var seconds = currentDate.getSeconds();
  
    return {
        year: year,
        month: month,
        monthName: monthName,
        day: day,
        dayName: dayName,
        hours: hours,
        minutes: minutes,
        seconds: seconds
    };
}