import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard } from './pages/Dashboard';
import { Inventory } from './pages/Inventory';
import { WorkInProgress } from './pages/WorkInProgress';
import { Login } from './pages/Login';

import { Navbar } from "./components/Navbar";

import 'semantic-ui-css/semantic.min.css';
import { useEffect, useState } from 'react';
import { Calendar } from './pages/Calendar';

const App = () => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    const paths = ['/json_files/generated_data.json', '/json_files/generated_data_objects.json'];
    const fetchData = async () => {
      try {
        const fetchedData = await Promise.all(paths.map(async (path) => {
          const response = await fetch(path);
          const jsonData = await response.json();
          return jsonData;
        }));

        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route key="login" path="/login" element={<Login />} />
        <Route path='/' element={<Navigate to='/login' />} />
        <Route path='/' element={<Navbar />}>
          <Route key="dashboard" path="/dashboard" exact element={<Dashboard jsonData={data} />} />
          <Route key="agenda" path="/agenda" exact element={ <Calendar /> } />
          <Route 
            key="inventory" 
            path="/inventory" exact 
            element={
              <Inventory
                filePath={'/json_files/generated_data.json'}
                catPath={'json_files/medical_device_info.json'}
                title={'Inventar Dispozitive Medicale'}
              />
            }
          />
          <Route 
            key="inventory-object" 
            path="/inventory-object" exact 
            element={
              <Inventory
                filePath={'/json_files/generated_data_objects.json'}
                catPath={'/json_files/objects_info.json'}
                title={'Inventar Obiecte'}
              />
            }
          />
        </Route>
        {/* Catch paths */}
        <Route path='*' element={<WorkInProgress />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
