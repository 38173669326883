import React, { useEffect, useState } from "react";
import { getCurrentDate, getDaysInMonth, /*months,*/ days } from "../components/constants/DateConstants";
import { Segment, Table } from "semantic-ui-react";

const CalendarView = (props) => {
    const [date, setDate] = useState(getCurrentDate());
    const [daysInMonth, setDaysInMonth] = useState([]);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setDate(getCurrentDate());
        }, 1000);

        const updateDaysInMonth = () => {
            const year = date.year;
            const month = date.month + 1;
    
            const currentDate = new Date();
            currentDate.setDate(0);
            const lastDayOfPreviousMonth = currentDate.getDate();
    
            const firstDayOfMonth = new Date(year, month, 1).getDay();
            const daysInMonthArray = Array.from({ length: getDaysInMonth(year, month) }, (_, index) => index + 1);
    
            const paddedDays = Array.from({ length: firstDayOfMonth }, (_, index) => lastDayOfPreviousMonth - firstDayOfMonth + 1 + index).concat(daysInMonthArray);
    
            const remainingEmptyCells = (7 - (paddedDays.length % 7)) % 7;
            const finalDays = [...paddedDays, ...Array.from({ length: 7 + remainingEmptyCells }, (_, index) => index + 1)];

            setDaysInMonth(finalDays);
        };

        updateDaysInMonth();

        return () => clearInterval(intervalId);
    }, [date]);

    var firstDay = false;
    var lastDay = false;

    return (
        <div className="agenda-calendar" style={{ height: '85vh' }}>
            <Segment attached><h1>{date.monthName} {date.year}</h1></Segment>
            <Table celled attached striped style={{ height: 'calc(100% - 65px)' }}>
                <Table.Header>
                    <Table.Row>
                        {days.map((day, index) => (
                            <Table.HeaderCell key={index} style={{ width: 'calc(100% / 7)' }}>{day}</Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {[0, 1, 2, 3, 4, 5].map(weekIndex => {
                        return(
                        <Table.Row key={weekIndex}>
                            {[0, 1, 2, 3, 4, 5, 6].map(dayIndex => {
                                const day = daysInMonth[weekIndex * 7 + dayIndex];
                                if(firstDay && day === 1) lastDay = true;
                                if(day === 1) firstDay = true;
                                const cellStyle = {
                                    whiteSpace: 'nowrap',
                                    color: (firstDay && !lastDay) ? 'black' : 'gray',
                                    backgroundColor: (firstDay && !lastDay && day===date.day) ? 'lightgreen' : ''
                                };
                                return (
                                    <Table.Cell key={dayIndex} style={cellStyle}>
                                        {day != null ? day : ''}
                                    </Table.Cell>
                                );
                            })}
                        </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        </div>
    )
}

export default CalendarView;