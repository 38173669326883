import React, { useEffect, useState, useRef } from "react";
import Plot from 'react-plotly.js';

const Charts = (props) => {
    const chartRef = useRef(null);
    const [chartDimensions, setChartDimensions] = useState({ width: '100%', height: '100%' });

    useEffect(() => {
        const handleResize = () => {
            const parent = chartRef.current;
            const newWidth = parent.offsetWidth;
            const newHeight = parent.offsetHeight;

            setChartDimensions({ width: newWidth, height: newHeight });
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const data = [
        {
            type: 'pie',
            hole: 0.4,
            labels: ['Functionale', 'Partial Functionale', 'Nefunctionale'],
            values: props.percentages,
            marker: {
                colors: ['#0074D9', '#FFDC00', '#FF4136'],
            },
        },
    ];

    const layout = {
        width: chartDimensions.width,
        height: chartDimensions.height,
        title: 'Starea Dispozitivelor',
        showlegend: true,
        legend: {
            orientation: 'h',
        },
    };

    return (
        <div className={'charts modal w30'} ref={chartRef}>
            <Plot
                data={data}
                layout={layout}
            />
        </div>
    );
}

export default Charts;