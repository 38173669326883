import React, { useEffect } from "react";

export const WorkInProgress = () => {
    useEffect(() => {
        document.title = "Ezymem | 404";
    }, []);
    return(
        <div className="modal">
            <img src="https://pbs.twimg.com/profile_images/1461315869330526209/_EiWIOLV_400x400.jpg" alt="404" />
            <h1>I'm sorry but how did you get here?</h1>
        </div>
    )    
}
