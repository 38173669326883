import React from "react";
import { Checkbox, Header, Search, Segment, Icon, Grid } from "semantic-ui-react";
import { ModalTemplatePage } from "./ModalAdd";

const ViewTargetElement = (props) => {
    return (
        <>
            <Header style={{padding: '0 0 0 15px'}}>
                <h2>{props.title} - Vizualizare Individuala</h2>
                <sub>
                    In aceasta sectiune puteti vizualiza toate datele si
                    documentele inregistrare pentru dispozitivul medical
                </sub>
            </Header>
            {Object.keys(props.catData).map((key, index) => {
                return (
                    <>
                        <Header as='h2' attached='top' dividing block >{key}</Header>
                        <Segment index={index} attached>
                            <ModalTemplatePage 
                                inputs={props.catData}
                                active={key}
                                disabled={!props.modifyDetails}
                                entries={props.data}
                                type='3Columns'
                                updateFormData={props.updateFormData}
                            />
                        </Segment>
                    </>
                );
            })}
            <Segment>
                <Header as='h2'>
                    {props.title === 'Inventar Dispozitive Medicale' ?
                    'Documente' :
                    props.title === 'Inventar Obiecte' ?
                    'Dispozitive Medicale' :
                    'undefined'}
                    </Header>
                <Search showNoResults style={{ marginBottom: '10px' }}></Search>
                {Object.keys(props.data).map((key, index) => {
                    return (
                        <Segment index={index} basic
                            style={{
                                borderRadius: '8px',
                                borderBottom: '1px solid rgba(0,0,0,0.25)',
                                margin: '0'
                            }}>
                            <Grid columns={3} verticalAlign="middle">
                                <Grid.Row>
                                    <Grid.Column width={2}>
                                        <Checkbox />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <b>{key}</b>: {props.data[key]}
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Icon name='eye' />
                                        <span style={{margin:'5px'}} />
                                        <Icon name='download' />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    );
                })}
            </Segment>
        </>
    );
};

export default ViewTargetElement;